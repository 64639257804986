/* eslint-disable */
// React Basic and Bootstrap
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';

//Import Components

//import images
import plannersImage from '../../assets/images/app/eventsphere-planners.png';
import vendorImage from '../../assets/images/app/eventsphere-vendors.png';
import attendeesImage from '../../assets/images/app/eventsphereng.png';

function AppShowCase() {
  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <React.Fragment>
      <section className="section pt-0 bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" className="text-center">
              <div className="section-title mb-4 pb-2">
                <h4 className="title mb-4">
                  Discover the <span className="text-primary">Ease</span> of Event Management
                </h4>
                <p className="text-muted para-desc mb-0 mx-auto">
                  <span className="text-primary fw-bold">Eventsphére</span> brings vendors,
                  planners, and attendees together on a single platform, simplifying the process of
                  creating and experiencing memorable events.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={8} md={12} className="mt-4 pt-2 text-center">
              <Nav pills justified id="pills-tab" className="flex-column flex-sm-row rounded">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' }, 'rounded')}
                    onClick={() => {
                      toggle('1');
                    }}
                  >
                    <div className="text-center py-2">
                      <h6 className="mb-0">For Planners</h6>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' }, 'rounded')}
                    onClick={() => {
                      toggle('2');
                    }}
                  >
                    <div className="text-center py-2">
                      <h6 className="mb-0">For Vendors</h6>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' }, 'rounded')}
                    onClick={() => {
                      toggle('3');
                    }}
                  >
                    <div className="text-center py-2">
                      <h6 className="mb-0">For Attendees</h6>
                    </div>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>

          <Row>
            <Col xs="12" className="mt-4 pt-2">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row className="align-items-center">
                    <Col md={6}>
                      <img
                        src={plannersImage}
                        className="img-fluid mx-auto d-block"
                        alt=""
                        style={{
                          maxWidth: '400px',
                        }}
                      />
                    </Col>

                    <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <div className="section-title">
                        <h4 className="title mb-4">
                          <i className="uil uil-angle-double-right text-primary"></i>Simplify
                          Coordination and Communication
                        </h4>
                        <p className="text-muted">
                          Organize all aspects of event planning in one convenient place with
                          Eventsphere. Coordinate with vendors, manage timelines, and keep your
                          clients updated effortlessly. Our platform is designed to streamline your
                          planning process, ensuring a seamless experience from start to finish.
                        </p>
                        <ul className="list-unstyled text-muted">
                          <li className="mb-1">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Unified Vendor Management: Curate your vendor list, compare quotes, and
                            handle bookings all from a single dashboard.
                          </li>
                          <li className="mb-1">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Client Collaboration Tool: Share updates, concepts, and timelines with
                            clients through a secure and easy-to-use portal.
                          </li>
                          <li className="mb-1">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Event Execution Suite: Access checklists, schedules, and real-time
                            updates to ensure every event runs smoothly.
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row className="align-items-center">
                    <Col md={6}>
                      <img
                        src={vendorImage}
                        className="img-fluid mx-auto d-block"
                        alt=""
                        style={{
                          maxWidth: '400px',
                        }}
                      />
                    </Col>

                    <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <div className="section-title">
                        <h4 className="title mb-4">
                          <i className="uil uil-angle-double-right text-primary"></i> Showcase Your
                          Talent and Win Bids
                        </h4>
                        <p className="text-muted">
                          Eventsphere is your gateway to showcasing your services to a wide audience
                          of event planners and attendees. Display your portfolio, bid for events,
                          and grow your business with our targeted exposure. Join us and transform
                          every celebration into an opportunity for success.
                        </p>
                        <ul className="list-unstyled text-muted">
                          <li className="mb-1">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Vendor Dashboard: Track your engagements, manage your calendar, and
                            analyze your performance metrics to grow your business.
                          </li>
                          <li className="mb-1">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Event Bidding System: Receive notifications of new events and use our
                            intuitive bidding system to secure bookings.
                          </li>
                          <li className="mb-1">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Portfolio Showcase: Create an attractive profile to display your past
                            work, customer reviews, and service offerings.
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row className="align-items-center">
                    <Col md={6}>
                      <img
                        src={attendeesImage}
                        className="img-fluid mx-auto d-block"
                        alt="Eventsphere Attendees image"
                        style={{
                          maxWidth: '400px',
                        }}
                      />
                    </Col>

                    <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <div className="section-title">
                        <h4 className="title mb-4">
                          <i className="uil uil-angle-double-right text-primary"></i> Engage and
                          Enjoy Without the Hassle
                        </h4>
                        <p className="text-muted">
                          RSVP with ease, stay informed about event details, and never miss a moment
                          with Eventsphere. Our platform keeps you connected with event updates,
                          allowing you to focus on celebrating the special occasions.
                        </p>
                        <ul className="list-unstyled text-muted">
                          <li className="mb-1">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Instant RSVP: Confirm your attendance with a single click and receive
                            immediate confirmation and event details.
                          </li>
                          <li className="mb-1">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Interactive Event Timeline: Stay up-to-date with the event schedule,
                            including any last-minute changes.
                          </li>
                          <li className="mb-1">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Social Integration: Connect with other attendees, share experiences, and
                            engage with event content on your favorite social platforms.
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default AppShowCase;
