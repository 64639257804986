// React Basic and Bootstrap
import React, { useEffect } from 'react';

// import generic component
import HeroSection from './HeroSection';
import AppFeature from './AppFeature';
import AppShowCase from './ShowCase';
import Popup from '../../components/Layout/popup';

function Index() {
  const scrollNavigation = () => {
    const doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };

  useEffect(() => {
    document.body.classList = '';
    window.addEventListener('scroll', scrollNavigation, true);
  });

  return (
    <>
      {/* section */}
      <HeroSection />

      <div className="position-relative">
        <div className="shape overflow-hidden text-light">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
          </svg>
        </div>
      </div>

      {/* Feature */}
      <section id="feature">
        <AppFeature />

        {/* ShowCase */}
        <AppShowCase />
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
            </svg>
          </div>
        </div>
      </section>

      <section id="contact">
        {/* Testi */}
        <div className="position-relative">
          <div className="shape overflow-hidden text-footer">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
            </svg>
          </div>
        </div>
      </section>

      <Popup />
    </>
  );
}

export default Index;
