import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

function SectionTitle({ title, desc, isLeft }) {
  return (
    <Row className="justify-content-center">
      <Col className={isLeft ? '' : 'col-12 text-center'}>
        <div className="section-title mb-4 pb-2">
          <h4 className="title mb-4"> {title}</h4>
          <p
            className={
              isLeft ? 'text-muted para-desc mb-0' : 'text-muted para-desc mb-0 mx-auto'
            }
            name="sectiondesc"
          >
            Start working with <span className="text-primary fw-bold">Eventsphère</span> {desc}
          </p>
        </div>
      </Col>
    </Row>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  isLeft: PropTypes.bool,
};

SectionTitle.defaultProps = {
  isLeft: false,
};

export default SectionTitle;
