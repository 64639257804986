import React from 'react';
import { Navigate } from 'react-router-dom';

import Apps from '../pages/Apps/index';

const routes = [
  //Index Main
  {
    path: '/',
    component: <Apps />,
    isTopbarDark: true,
  },
  { path: '/index', component: <Navigate to="/" />, isTopbarDark: true },
];

export default routes;
