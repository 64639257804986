import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

// Import images
import homeImage from '../../assets/images/app/eventsphereng.png';

function HeroSection() {
  return (
    <section className="bg-half-170 d-table w-100" id="home">
      <Container>
        <Row className="mt-5 align-items-center">
          <Col lg={6} md={7}>
            <div className="title-heading">
              <h1 className="heading mb-3">
                <span className="text-primary">eventsphère</span> app Redefining the art of
                celebration
              </h1>
              <p className="para-desc text-muted">
                all-in-one social event mobile app that goes beyond event planning and management.
                Eventsphère app allows creators, event organizers, and attendees to curate their
                unique and personalized event experience, all with the shared goal of celebrating
                important milestones and having fun with family and friends.
              </p>
              <div className="mt-4">
                <Link to="#" className="btn btn-primary mt-2 me-2">
                  <i className="uil uil-apple" /> Comming Soon
                </Link>{' '}
                <Link to="#" className="btn btn-outline-primary mt-2">
                  <i className="uil uil-google-play" /> Comming Soon
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={6} md={5} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div className="text-md-end text-center ms-lg-4">
              <img src={homeImage} className="img-fluid" alt="" style={{
                maxWidth: '400px',
              }} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HeroSection;
