/* eslint-disable */

// Blog Images



const appsFeatures = [
  {
    icon: 'monitor',
    title: 'Vendor Selection',
    desc: 'Choose from a curated list of vendors or search for vendors based on your specific needs.',
  },
  {
    icon: 'feather',
    title: 'Real-Time Updates',
    desc: 'Get real-time updates on RSVPs, guest lists, and other event details.',
  },
  {
    icon: 'eye',
    title: 'Chat & Messaging',
    desc: 'Communicate with guests and vendors through in-app messaging and chat features.',
  },
  {
    icon: 'user-check',
    title: 'Photo & Video Sharing',
    desc: 'Share and collect photos and videos from the event with ease.',
  },
  {
    icon: 'smartphone',
    title: 'Social Media Integration',
    desc: 'Connect your event with social media to promote it and engage with attendees.',
  },
  {
    icon: 'heart',
    title: 'Gift Registry Integration',
    desc: 'Integrate with popular gift registries to make gift-giving easier for guests and hosts.',
  },
];


export {
  appsFeatures,
};
